ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--nav-size);
  margin-top: 1rem;
  z-index: 1000;
}

.navbar-nav {
  max-width: 98%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.navbar-nav li:first-child {
  margin-right: auto;
}

.swecc-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-item,
.nav-toggle {
  width: calc(var(--nav-size) * 0.8);
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.nav-item {
  margin: 8px 20px 8px 8px;
}
.nav-toggle {
  margin: 8px 8px 8px 8px;
}

.nav-Logo-Item {
  height: 50px;
  width: 10vw;
  box-sizing: border-box;
  margin: 8px 8px 8px 0;
}

.icon-button,
.toggle-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--bg-accent);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  border: 2px solid var(--bg-hover);
}

.toggle-button {
  box-shadow: 0 0 40px 15px var(--nav-toggle);
  transition: all 0.8s ease;
}

.toggle-button svg {
  width: 22px;
  height: 22px;
}

.toggle-button.dark-mode svg {
  color: var(--moon-color);
}

.toggle-button.light-mode svg {
  color: var(--sun-color);
}

.nav-page {
  margin-top: 23px;
}

.nav-expand {
  margin: 0;
}

.icon-button:hover,
.toggle-button:hover {
  background-color: var(--bg-hover);
  transition: all var(--speed) ease;
  cursor: pointer;
}

.icon-button.no-action,
.toggle-button.no-action {
  cursor: default;
  pointer-events: none;
  border: none;
  background-color: var(--bg);
}

.icon-button.tooltip,
.toggle-button.tooltip {
  position: relative;
}

.icon-button.tooltip::before,
.toggle-button.tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 3px 8px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
}

.icon-button.tooltip:hover::before,
.toggle-button.tooltip:hover::before {
  opacity: 1;
}

.icon-button svg {
  fill: var(--text-color);
  width: 22px;
  height: 22px;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10%) translateX(-45%);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-45%);
    opacity: 1;
    transition: all 0.2s ease;
  }
}

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  transition: all 0.2s ease;
  background-color: var(--bg);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  box-shadow: 0 10px 10px #00000033;
  z-index: 1000;
  animation: slideDown 0.3s ease;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  cursor: pointer;
}

.menu-item .icon-button {
  transition: all 0.5s ease;
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: var(--bg-hover);
}

.icon-right {
  margin-left: auto;
  background-color: var(--bg-accent);
  border-radius: 50%;
  display: flex;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

@media (prefers-reduced-motion: no-preference) {
  .pulse {
    animation: smooth-pulse 0.5s cubic-bezier(0.39, 0.575, 0.565, 1); 
  }

  @keyframes smooth-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1)
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    margin: 0;
  }

  .nav-Logo-Item {
    margin: 0;
  }

  .swecc-logo {
    width: 20vw;
    height: 20vw;
    object-fit: cover;
  }
}
